import { createApp, createSSRApp, defineAsyncComponent } from 'vue';
import router from './router';
import store from './store';
import { getInitialFilters } from '@/helpers';
import { WindowFilterInitListing } from './types';

console.log('v11');
// set data from page js
// On yarn serve, some things fire twice due to multiple mounts of the same app (eg: VehicleApp on savedvehiclesRoot and vehiclesRoot) but this shouldn't happen on build as saved isn't on the same page as vehicles. To fix you can comment out saved or vehicles etc in this file.

const filterInit: WindowFilterInitListing = (window as any).uvFilterInit;
const allStockCategoryIds = filterInit.FilterConditions?.map(o => o?.LocationId) || filterInit?.StockCategoryIds || [];
const stockCategoryIds = filterInit?.StockCategoryIds?.length ? filterInit?.StockCategoryIds : [(window as any).uvSearchConditionDefault];
const showLocation = filterInit.FilterLocations?.length > 1;
const hasPreYearValue = filterInit.HasPreYearValue;

let savedVehicles: Array<string> = [];
let viewedVehicles: Array<string> = [];
for (let i = 0; i != allStockCategoryIds.length; ++i){
	const rawSavedVehicles = localStorage.getItem('uvSaved-' + allStockCategoryIds[i]) || '[]';
	const rawViewedVehicles = localStorage.getItem('uvRecentlyViewed-' + allStockCategoryIds[i]) || '[]';
	savedVehicles = [...savedVehicles, ...(JSON.parse(rawSavedVehicles))];
	viewedVehicles = [...viewedVehicles, ...(JSON.parse(rawViewedVehicles))];
}

store.commit('setHasPreYearValue', hasPreYearValue);
store.commit('setShowLocation', showLocation);
store.commit('setLocations', filterInit?.FilterLocations || []);
store.commit('setCtas', (window as any).uvCtas);
store.commit('setBrandId', (window as any).uvBrandId);
store.commit('setStockCategoryIds', stockCategoryIds);
store.commit('setSavedVehicles', savedVehicles);
store.commit('setViewedVehicles', viewedVehicles);
store.commit('setVehicles', filterInit?.InitialVehicles || []);
store.commit('setTotalVehicles', (window as any).uvTotalVehicles || -1);

store.commit('filters/setFilters', getInitialFilters());

if (hasPreYearValue){
	store.commit('filters/setFilters', {
		...store.state.filters, 
		yearMin: filterInit.YearMin - 1
	});
}

const topBarRoot = document.getElementById('uv-topbar-root');
const filtersRoot = document.getElementById('uv-filters-root');
const vehiclesRoot = document.getElementById('uv-vehicles-static');
const savedVehiclesRoot = document.getElementById('uv-savedvehicles-root');
const paginationRoot = document.querySelector("div.pagination > div");
// const routerRoot = document.getElementById("uv-router-root");
const searchRoot = document.getElementById("search-cars-root");
const searchFullRoot = document.getElementById("search-full-root");
const detailEnquireRoot = document.querySelectorAll(".uv-enquire-modal-button");
const detailSidebarRoot = document.getElementById("uv-sidebar-root");
const modalRoot = document.getElementById("uv-modal-root");
const detailCertifiedRoot = document.querySelectorAll(".uv-tile-toyotacertified");

if (topBarRoot){
	const TopbarApp = defineAsyncComponent(() => import(/* webpackChunkName: "topbar" */'@/TopbarApp/TopbarApp.vue'));
	createApp(TopbarApp).use(store).mount(topBarRoot);
}
if (filtersRoot){
	const FilterApp = defineAsyncComponent(() => import(/* webpackChunkName: "filter" */'@/FilterApp/FilterApp.vue'));
	createApp(FilterApp).use(store).use(router).mount(filtersRoot);
}
if (vehiclesRoot){
	const VehicleApp = defineAsyncComponent(() => import(/* webpackChunkName: "vehicle" */'@/VehicleApp/VehicleApp.vue'));
	removeWhiteSpaceFromTextNodeAndNormalize(vehiclesRoot, true);
	createSSRApp(VehicleApp).use(store).mount(vehiclesRoot);
}
else if (savedVehiclesRoot){
	const VehicleApp = defineAsyncComponent(() => import(/* webpackChunkName: "vehicle" */'@/VehicleApp/VehicleApp.vue'));
	createApp(VehicleApp).use(store).mount(savedVehiclesRoot);
	let saved: Array<string> = stockCategoryIds.length ? [] : savedVehicles;
	if (stockCategoryIds.length){
		for (const i in stockCategoryIds){
			const item: string = localStorage.getItem(`uvSaved-${stockCategoryIds[i]}`) || "[]";
			const arr: Array<string> = JSON.parse(item);
			saved = [...saved, ...arr];
		}
	}
	if (saved.length){
		store.dispatch('updateVehicles', { amend: false, regos: saved})
	}
}
if (paginationRoot){
	const PaginationApp = defineAsyncComponent(() => import(/* webpackChunkName: "pagination" */'@/PaginationApp/PaginationApp.vue'));
	removeWhiteSpaceFromTextNodeAndNormalize(paginationRoot, true);
	createSSRApp(PaginationApp).use(store).mount(paginationRoot);
}
// if (routerRoot){
// 	const RouterApp = defineAsyncComponent(() => import(/* webpackChunkName: "router" */'@/RouterApp.vue'));
// 	createApp(RouterApp).use(store).use(router).mount(routerRoot);
// }
if (searchRoot){
	const SearchApp = defineAsyncComponent(() => import(/* webpackChunkName: "search" */'@/SearchApp/SearchApp.vue'));
	createApp(SearchApp).use(store).mount(searchRoot);
}
if (searchFullRoot){
	const SearchFullApp = defineAsyncComponent(() => import(/* webpackChunkName: "search-full" */'@/SearchFullApp/SearchFullApp.vue'));
	createApp(SearchFullApp).use(store).mount(searchFullRoot);
}
if (detailEnquireRoot.length){
	const DetailEnquireApp = defineAsyncComponent(() => import(/* webpackChunkName: "detail-enquire" */'@/DetailApp/DetailEnquireApp.vue'));
	for (let i = 0; i != detailEnquireRoot.length; ++i){
		removeWhiteSpaceFromTextNodeAndNormalize(detailEnquireRoot[i], true);
		createSSRApp(DetailEnquireApp).use(store).mount(detailEnquireRoot[i]);
	}
}
if (detailSidebarRoot){
	const DetailSidebarApp = defineAsyncComponent(() => import(/* webpackChunkName: "detail-sidebar" */'@/DetailApp/DetailSidebarApp.vue'));
	removeWhiteSpaceFromTextNodeAndNormalize(detailSidebarRoot, true);
	createSSRApp(DetailSidebarApp).use(store).mount(detailSidebarRoot);
}
if (modalRoot){
	const ModalApp = defineAsyncComponent(() => import(/* webpackChunkName: "modal" */'@/ModalApp/ModalApp.vue'));
	createApp(ModalApp).use(store).mount(modalRoot);
}
if (detailCertifiedRoot.length){
	const DetailCertifiedApp = defineAsyncComponent(() => import(/* webpackChunkName: "detail-certified" */'@/DetailApp/DetailCertifiedApp.vue'));
	for (let i = 0; i != detailEnquireRoot.length; ++i){
		createApp(DetailCertifiedApp).use(store).mount(detailCertifiedRoot[i]);
	}
}

function removeWhiteSpaceFromTextNodeAndNormalize(parentEl: ChildNode, normalize: boolean){
	for (const el of parentEl.childNodes){
		if (el.nodeType === Node.TEXT_NODE){
			if (el.nodeValue){
				el.nodeValue = el.nodeValue.replace(/^\s+$/g, '');
			}
		}
		else {
			removeWhiteSpaceFromTextNodeAndNormalize(el, false);
		}
	}
	normalize && parentEl.normalize();
}