import { createStore, Dispatch } from 'vuex';
import { Commit } from 'vuex/types';
import { 
	IStoreState, 
	IVehicle, 
	ILocation, 
	IEnquiryModal, 
	IVehicleEndpointResponse,
	IFiltersState
} from '@/types.d';

import filters from '@/FilterApp/store';
import { 
	vehiclesEndpoint, 
	defaultHeaders, 
	authEndpoint 
} from '@/config'
import { getFilterObject, getRegosPostObject } from '@/helpers';

export default createStore<IStoreState>({
	modules: {
		filters
	},
	state: {
		hasMadeInitialUpdateVehicles: false,
		isRetrievingData: false,
		brandId: -1,
		stockCategoryIds: [],
		bearerToken: '',
		isFilterOpen: false, 
		sort: 0, 
		showLocation: false,

		locations: [], 
		customer: {
			name: "", 
			email: "",
			phone: "",
			preferredLocation: null, 
			date: "",
			time: "",
			subscribe: true
		}, 
		enquiryModal: {
			isVisible: false,
			type: null,
			vehicle: null
		},
		vehiclesText: "",
		vehiclesIndex: 0,
		totalVehicles: -1,
		ctas: [],
		
		savedVehicles: [],
		viewedVehicles: [],
		vehicles: [],

		hasPreYearValue: false
	},
	getters: {
	},
	mutations: {
		setHasMadeInitialUpdateVehicles(state, payload: boolean){
			state.hasMadeInitialUpdateVehicles = payload;
		},
		setHasPreYearValue(state, payload: boolean){
			state.hasPreYearValue = payload;
		},
		setVehiclesIndex(state, payload: number){
			state.vehiclesIndex = payload;
		},
		setIsRetrievingData(state, payload: boolean){
			state.isRetrievingData = payload;
		},
		setShowLocation(state, payload: boolean){
			state.showLocation = payload;
		}, 
		setLocations(state, payload: Array<ILocation>){
			state.locations = payload;
		}, 
		resetEnquiryModal(state){
			document.body.classList.remove('uv-modal-open');
			
			state.enquiryModal.isVisible = false;
			state.enquiryModal.type = null;
			state.enquiryModal.vehicle = null;
		},
		setEnquiryModal(state, payload: IEnquiryModal){
			document.body.classList.toggle('uv-modal-open', payload.isVisible);

			state.enquiryModal.isVisible = payload.isVisible;
			state.enquiryModal.type = payload.type;
			state.enquiryModal.vehicle = payload.vehicle;
		},
		setCtas(state, payload: Array<string>){
			state.ctas = payload;
		},
		setFilterOpen(state, payload: boolean){
			state.isFilterOpen = payload;
		},
		setBrandId(state, payload: number){
			state.brandId = payload;
		}, 
		setStockCategoryIds(state, payload: Array<number>){
			state.stockCategoryIds = payload;
		}, 
		setSort(state, payload: number){
			state.sort = payload;
		}, 
		addVehicles(state, payload: Array<IVehicle>){
			state.vehicles = [...state.vehicles, ...payload];
		},
		setVehicles(state, payload: Array<IVehicle>){
			state.vehicles = payload;
		},
		setSavedVehicles(state, payload: Array<string>){
			state.savedVehicles = payload;
		},
		addSavedVehicle(state, payload: string){
			state.savedVehicles.push(payload);
		},
		removeSavedVehicle(state, payload: string){
			state.savedVehicles = state.savedVehicles.filter((o: string) => o != payload);
		},
		setViewedVehicles(state, payload: Array<string>){
			state.viewedVehicles = payload;
		},
		setTotalVehicles(state, payload){
			state.totalVehicles = payload;
		}, 
		setBearerToken(state, payload){
			state.bearerToken = payload;
		}
	},
	actions: {
		async fetchBearerToken({ commit }: { commit: Commit }){
			await fetch(authEndpoint, {
				body: 'grant_type=password',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				method: 'POST'
			}).then(response => response.json()).then(data => {
				commit('setBearerToken', data.access_token);
			}).catch(error => {
				console.error(error);
			});
		}, 
		async updateVehicles(
			{ commit, dispatch, state }: { commit: Commit, dispatch: Dispatch, state: IStoreState }, 
			{ amend, regos, firstCall }: { amend: boolean, regos: Array<string>, firstCall: boolean}
		){
			if (firstCall && state.hasMadeInitialUpdateVehicles) return;
			commit('setIsRetrievingData', true);
			commit('setHasMadeInitialUpdateVehicles', true);
			const filter: any = regos ? getRegosPostObject(regos, state.stockCategoryIds) : getFilterObject(state.filters, state.sort, state.vehiclesIndex);
			if (!state.bearerToken){
				await dispatch('fetchBearerToken');
				if (!state.bearerToken){
					commit('setIsRetrievingData', false);
					return;
				}
			}
			const body: string = regos ? JSON.stringify(filter) : JSON.stringify(filter).toLowerCase();
			const url = `${vehiclesEndpoint}?dealerId=${(window as any).uvDealerId}`;
			await fetch(url, {
				method: 'POST',
				body,
				headers: defaultHeaders(state.bearerToken)
			}).then(response => {
				if (response.status === 401) {
					commit('setBearerToken', '');
					dispatch('updateVehicles', { amend, regos });
					return {};
				}
				return response.json();
			}).then((json: IVehicleEndpointResponse) => {
				if (json.Vehicles){
					commit(amend ? 'addVehicles' : 'setVehicles', json.Vehicles);
					const initialYearMin = (window as any).uvFilterInit.YearMin;
					const hasPreYearValue: boolean = state.hasPreYearValue;
					const preYearValue: number = initialYearMin - 1;
					const finalYearMin: number = hasPreYearValue && filter.ymi === 0 ? preYearValue : filter.ymi || (window as any).uvFilterInit.YearMin;
					const newFilters: IFiltersState = {
						conditions: json.FilterConditions || [],
						bodies: json.FilterBodies || [],
						certified: json.FilterCertified || [],
						fuels: json.FilterFuel || [],
						locations: json.FilterLocations || [],
						makes: json.FilterMakes || [],
						models: json.FilterModels || [],
						transmissions: json.FilterTransmissions || [],
						priceMin: filter.pmi || (window as any).uvFilterInit.PriceMin,
						priceMax: filter.pma || (window as any).uvFilterInit.PriceMax,
						yearMin: finalYearMin,
						yearMax: filter.yma || (window as any).uvFilterInit.YearMax
					};
					commit('filters/setFilters', newFilters);
					commit('setTotalVehicles', json.TotalVehicles);
				}
				else if (json.Message){
					console.log('Error: ' + json.Message);
				}
			}).catch(error => {
				console.log(error);
			});
			commit('setIsRetrievingData', false);
		}
	}
});
